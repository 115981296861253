var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "404-page h-100 content-center" }, [
    _c("section", [
      _c(
        "div",
        { staticClass: "container py-5 text-center" },
        [
          _c("h1", { staticClass: "text-light display-3" }, [_vm._v("404")]),
          _vm._v(" "),
          _c("p", { staticClass: "text-muted" }, [
            _vm._v(
              "We are sorry but your request contains bad syntax and cannot be fulfilled.."
            )
          ]),
          _vm._v(" "),
          _c("router-link", { staticClass: "btn", attrs: { to: "/" } }, [
            _vm._v("Go Home")
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }